exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-abbreviation-js": () => import("./../../../src/templates/abbreviation.js" /* webpackChunkName: "component---src-templates-abbreviation-js" */),
  "component---src-templates-abbreviations-page-js": () => import("./../../../src/templates/abbreviationsPage.js" /* webpackChunkName: "component---src-templates-abbreviations-page-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/aboutUsPage.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-condition-js": () => import("./../../../src/templates/condition.js" /* webpackChunkName: "component---src-templates-condition-js" */),
  "component---src-templates-conditions-page-js": () => import("./../../../src/templates/conditionsPage.js" /* webpackChunkName: "component---src-templates-conditions-page-js" */),
  "component---src-templates-contact-location-js": () => import("./../../../src/templates/contactLocation.js" /* webpackChunkName: "component---src-templates-contact-location-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-department-category-js": () => import("./../../../src/templates/departmentCategory.js" /* webpackChunkName: "component---src-templates-department-category-js" */),
  "component---src-templates-departments-page-js": () => import("./../../../src/templates/departmentsPage.js" /* webpackChunkName: "component---src-templates-departments-page-js" */),
  "component---src-templates-general-contact-page-js": () => import("./../../../src/templates/generalContactPage.js" /* webpackChunkName: "component---src-templates-general-contact-page-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-guides-page-js": () => import("./../../../src/templates/guidesPage.js" /* webpackChunkName: "component---src-templates-guides-page-js" */),
  "component---src-templates-helpers-page-js": () => import("./../../../src/templates/helpersPage.js" /* webpackChunkName: "component---src-templates-helpers-page-js" */),
  "component---src-templates-info-item-js": () => import("./../../../src/templates/infoItem.js" /* webpackChunkName: "component---src-templates-info-item-js" */),
  "component---src-templates-investigations-page-js": () => import("./../../../src/templates/investigationsPage.js" /* webpackChunkName: "component---src-templates-investigations-page-js" */),
  "component---src-templates-medics-info-page-js": () => import("./../../../src/templates/medicsInfoPage.js" /* webpackChunkName: "component---src-templates-medics-info-page-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/newsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-patients-info-page-js": () => import("./../../../src/templates/patientsInfoPage.js" /* webpackChunkName: "component---src-templates-patients-info-page-js" */),
  "component---src-templates-prices-page-js": () => import("./../../../src/templates/pricesPage.js" /* webpackChunkName: "component---src-templates-prices-page-js" */),
  "component---src-templates-procedure-js": () => import("./../../../src/templates/procedure.js" /* webpackChunkName: "component---src-templates-procedure-js" */),
  "component---src-templates-procedures-page-js": () => import("./../../../src/templates/proceduresPage.js" /* webpackChunkName: "component---src-templates-procedures-page-js" */),
  "component---src-templates-question-item-page-js": () => import("./../../../src/templates/questionItemPage.js" /* webpackChunkName: "component---src-templates-question-item-page-js" */),
  "component---src-templates-questions-page-js": () => import("./../../../src/templates/questionsPage.js" /* webpackChunkName: "component---src-templates-questions-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/teamMember.js" /* webpackChunkName: "component---src-templates-team-member-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/teamPage.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-templates-testimonials-page-js": () => import("./../../../src/templates/testimonialsPage.js" /* webpackChunkName: "component---src-templates-testimonials-page-js" */)
}

