import CloseIcon from "@mui/icons-material/Close"
import MenuIcon from "@mui/icons-material/Menu"
import PublishIcon from "@mui/icons-material/Publish"
import SearchIcon from "@mui/icons-material/Search"
import { Box } from "@mui/material"
import { fbEvents } from "../../services/fbConversionsApi"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { Link, graphql, useStaticQuery } from "gatsby"
import { groupBy } from "lodash"
import React, { useContext, useState } from "react"
import ScrollToTop from "react-scroll-up"
import shortid from "shortid"

import { useSearch } from "../../contexts/GlobalSearchProvider"
import LangContext from "../../contexts/LangContext"
import LocationContext from "../../contexts/LocationContext"
import { useMainMenu } from "../../contexts/MainMenuProvider"
import MenuButton from "../buttons/MenuButton"
import PhoneIcon from "../icons/PhoneIcon"
import Logo from "./Logo"
import MobileMenu from "./MobileMenu"

const Header = () => {
  const location = useContext(LocationContext)
  const lang = useContext(LangContext)
  const { openSearch } = useSearch()
  const { openMenu, closeMenu } = useMainMenu()
  const [hasBorder, setHasBorder] = useState(false)

  const i18n = lang.i18n[lang.locale]

  const data = useStaticQuery(query)

  const mainMenu = data.mainMenus.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data.links)
    .pop()

  const sublinks = data.mainMenus.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data.sublinks)
    .pop()

  const [open, setOpen] = useState(false)

  const toggleMobileMenu = () => {
    if (open) {
      closeMenu()
    } else {
      openMenu()
    }

    setOpen(!open)
  }

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y > -50) {
        setHasBorder(false)
      } else {
        setHasBorder(true)
      }
    },
    [hasBorder],
    null,
    false,
    0,
  )

  const sublinksGrouped = groupBy(sublinks, sl => sl.key)

  mainMenu.forEach(mm => {
    mm.sublinks = sublinksGrouped[mm.sublinks_key]
  })

  return (
    <Box
      sx={{
        width: "100%",
        position: "sticky",
        top: 0,
        zIndex: 99999,
        background: theme => theme.palette.common.white,
        marginBottom: {
          xs: "30px",
          md: "60px",
        },

        height: {
          xs: 50,
          md: 70,
        },
        boxShadow: hasBorder ? "0px -1px 5px -2px #000" : "none",
      }}
    >
      <Box
        sx={{
          display: "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: 25,
          height: 25,
          zIndex: 99999,
          color: "white",
          "&::before": {
            content: {
              xs: "'xs'",
              sm: "'sm'",
              md: "'md'",
              lg: "'lg'",
              xl: "'xl'",
            },
          },
          background: {
            xs: "red",
            sm: "green",
            md: "blue",
            lg: "yellow",
            xl: "orange",
          },
        }}
      />

      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
          pointerEvents: "none",

          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={4} sm={2}>
            <Logo />
          </Grid>
          <Grid item sm={10}>
            <Toolbar
              component="nav"
              variant="dense"
              disableGutters
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                padding: 0,
                pointerEvents: "auto",
                background: theme => theme.palette.common.white,
                paddingTop: 1,
              }}
            >
              {mainMenu.map(item => (
                <div key={`menu-item-${item.label}`}>
                  {item.sublinks && (
                    <MenuButton item={item} showBorder={hasBorder} />
                  )}
                  {!item.sublinks && (
                    <Button
                      size="small"
                      variant="text"
                      to={
                        i18n.default
                          ? `/${item.internal}`
                          : `/${i18n.path}/${item.internal}`
                      }
                      sx={{
                        padding: 1,
                        flexShrink: 0,
                        fontWeight: 300,
                        fontSize: 14,
                        marginBottom: 1,
                        color: theme => theme.palette.common.black,
                        marginTop: 1,
                        "&:hover": {
                          backgroundColor: "transparent",
                          color: theme => theme.palette.aresBlue.main,
                        },

                        ...(location.pathname.match(item.internal) && {
                          color: theme => theme.palette.aresBlue.main,
                          fontWeight: 600,
                          position: "relative",
                          "&:after": {
                            content: '" "',
                            height: 3,
                            width: "100%",
                            position: "absolute",
                            bottom: -4,
                            zIndex: 1000,
                          },
                        }),
                      }}
                      component={Link}
                    >
                      {item.label}
                    </Button>
                  )}
                </div>
              ))}

              <Button
                component="a"
                href="tel:+40319300"
                size="small"
                variant="text"
                onClick={() => {
                  fbEvents.contact({
                    content_name: 'Phone Call - Header',
                    content_category: 'Contact',
                  });
                }}
                sx={{
                  padding: 1,
                  flexShrink: 0,
                  fontSize: 14,
                  marginBottom: 1,
                  marginTop: 1,
                  color: theme => theme.palette.common.white,
                  fontWeight: 700,
                  background: theme => theme.palette.secondary.main,
                  borderRadius: 25,
                  paddingY: 1,
                  paddingX: 2,
                  border: "1px solid transparent",

                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme => theme.palette.aresBlue.main,
                    border: theme => `1px solid ${theme.palette.primary.main}`,
                  },
                }}
              >
                <PhoneIcon
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: 4,
                  }}
                />
                <div>031 9300</div>
              </Button>

              <Button
                size="small"
                key={shortid.generate()}
                variant="text"
                sx={{
                  padding: 1,
                  flexShrink: 0,
                  fontWeight: 300,
                  fontSize: 14,
                  marginBottom: 1,
                  marginTop: 1,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: theme => theme.palette.aresBlue.main,
                  },
                }}
                onClick={openSearch}
              >
                <SearchIcon style={{ fontSize: 23 }} />
              </Button>
            </Toolbar>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          justifyContent: "space-between",
          alignItems: "flex-start",
          pointerEvents: "none",

          display: {
            md: "none",
            lg: "none",
            xl: "none",
            xs: "flex",
          },
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={4} sm={2}>
            <Logo />
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleMobileMenu}
              sx={{
                pointerEvents: "auto",
              }}
              size="large"
            >
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>

            {open && (
              <MobileMenu
                menu={mainMenu}
                location={location}
                toggleMobileMenu={toggleMobileMenu}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <ScrollToTop
        className="scrollUpBtn"
        showUnder={160}
        style={{ bottom: 70, right: 20, zIndex: 9999 }}
      >
        <IconButton
          sx={{
            background: "rgba(0, 0, 0, 0.04)",

            "&:hover": {
              color: theme => theme.palette.common.white,
              background: theme => theme.palette.aresBlue.main,
            },
          }}
          aria-label="delete"
          size="large"
        >
          <PublishIcon />
        </IconButton>
      </ScrollToTop>
    </Box>
  )
}

export default Header

const query = graphql`
  query HeaderQuery {
    mainMenus: allPrismicMainMenu {
      edges {
        node {
          lang
          data {
            title
            links {
              label
              internal
              sublinks_key
              url {
                link_type
                url
                target
              }
            }
            sublinks {
              key
              label
              internal
              url {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
  }
`

Header.propTypes = {}
