import { Box, useMediaQuery, useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import axios from "axios"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useContext, useRef, useState } from "react"

import { fbEvents } from "../../services/fbConversionsApi"

import LangContext from "../../contexts/LangContext"
import CustomButton from "../buttons/CustomButton"
import CustomTextField from "../form/CustomTextField"
import CustomSelect from "../form/CustomSelect"

const GeneralContact = ({
  formName = "Contact Form",
  location,
  selectedHelperUid = "-",
  selectedTeamMemberUid = "-",
  selectedContactLocationUid = "-",
  showContactLocationSelector = false,
  contactLocations = [],
  showTeamMemberSelector = false,
  teamMembers = [],
  showDateSelector = false,
  spread = false,
  contactMessage,
  disclaimerMessage = "",
  redirectTo,
  sendToCRM = true,
  additionalData = {},
  ...props
}) => {
  const theme = useTheme()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const formNameRef = useRef(null)
  const sourceRef = useRef(null)
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)
  const phoneRef = useRef(null)
  const messageRef = useRef(null)
  // const newsletterRef = useRef(null)
  const helperRef = useRef(null)
  const teamMemberRef = useRef(null)
  const contactLocationRef = useRef(null)

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(selectedContactLocationUid)
  const [filteredTeamMembers, setFilteredTeamMembers] = useState(teamMembers)

  const { className } = props

  if (!disclaimerMessage || disclaimerMessage.length <= 0) {
    disclaimerMessage = i18n.translations.contactDisclaimer
  }

  if (!contactMessage || contactMessage.length <= 0) {
    contactMessage = i18n.translations.contactMessage
  }

  const contactLocationsOptions = contactLocations.map(cl => ({
    label: cl?.data?.title?.text,
    value: cl?.uid,
  }))

  const teamMemberOptions = filteredTeamMembers.map(tm => ({
    label: tm.data.name.text,
    value: tm.uid,
  }))

  const send_site_data = (utterance, entity_type, entity_data) => {
    try {
      utterance = utterance || "_send_client_data_"
      entity_type = entity_type || "SiteData"
      trigger_flow(utterance, entity_type, entity_data)
    } catch (e) { }
  }

  const trigger_flow = (utterance, entity_type, entity_data) => {
    const payload = {
      name: utterance,
      value: {
        $entityTypeName$: entity_type,
        ...entity_data,
      },
    }

    // eslint-disable-next-line no-undef
    DruidWebchat_v2.sendEvent(payload)
  }

  const handleLocationChange = event => {
    const selectedValue = event.target.value;

    // Update selected location
    setSelectedLocation(selectedValue);

    // Filter team members based on selected location
    const selectedLocationObj = contactLocations.find(cl => cl.uid === selectedValue);

    if (selectedLocationObj && selectedLocationObj.data.team_members) {
      // Extract team members from the location
      const locationTeamMembers = selectedLocationObj.data.team_members
        .filter(tm => tm.team_member && tm.team_member.document)
        .map(tm => ({
          uid: tm.team_member.document.uid,
          data: {
            name: tm.team_member.document.data.name,
            entitling: tm.team_member.document.data.entitling,
            specialisations: tm.team_member.document.data.specialisations
          }
        }));

      setFilteredTeamMembers(locationTeamMembers);
    } else {
      setFilteredTeamMembers([]);
    }

    // Reset team member selection if one was already selected
    if (teamMemberRef.current) {
      teamMemberRef.current.value = "";
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)

    const formData = {
      "form-name": formNameRef.current.value,
      source: sourceRef.current.value,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      helper: helperRef.current.value,
      teamMember: teamMemberRef.current.value,
      contactLocation: contactLocationRef.current.value,
      // newsletter: newsletterRef.current.checked,
      message: messageRef.current.value,
      ...additionalData,
    }
    
    // Track form submission event with Facebook Conversions API
    const fullName = `${firstNameRef.current.value} ${lastNameRef.current.value}`.trim();
    fbEvents.lead({
      content_name: formNameRef.current.value,
      content_category: 'Form Submission',
      status: 'submitted',
      team_member: teamMemberRef.current.value || '',
      contact_location: contactLocationRef.current.value || '',
      source: sourceRef.current.value || '',
    }, {
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
    });

    if (sendToCRM) {
      send_site_data("_send_client_data_", "SiteData", {
        ContactFormSite: formNameRef.current.value,
        Origin: sourceRef.current.value,
        FirstName: firstNameRef.current.value,
        LastName: lastNameRef.current.value,
        Email: emailRef.current.value,
        MainPhone: phoneRef.current.value,
        Helper: helperRef.current.value,
        Medic: teamMemberRef.current.value,
        ContactLocation: contactLocationRef.current.value,
        InfoAresSite: messageRef.current.value,
        // Newsletter: newsletterRef.current.checked,
      })
    }

    const axiosOptions = {
      url: location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: queryString.stringify(formData),
    }

    axios(axiosOptions)
      .then(response => {
        //  setSubmitted(true)
        //  setLoading(false)
        //  window.history.pushState("", "", `${location.pathname}multumim/`)
      })
      .catch(err => {
        console.log(err)
        // setSubmitted(false)
        // setLoading(false)
      })

    setSubmitted(true)
    setLoading(false)

    if (redirectTo) {
      window.history.pushState("", "", redirectTo)
    }
  }

  let dense = useMediaQuery(theme.breakpoints.up("sm"))

  if (spread) {
    dense = false
  }

  return (
    <Box
      className={className}
      sx={{
        background: "#f7941d",
        padding: 3,
        height: "100%",
        ...props.sx,
      }}
    >
      {submitted && (
        <Typography
          sx={{
            color: theme => theme.palette.common.white,
            fontSize: 18,
            textAlign: "center",
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          {i18n.translations.messageReceivedConfirmation}
        </Typography>
      )}
      {!submitted && (
        <>
          <form
            name={formName}
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={event => handleSubmit(event)}
          >
            <input
              type="hidden"
              name="form-name"
              value={formName}
              ref={formNameRef}
            />

            <input
              type="hidden"
              name="source"
              value={location.href}
              ref={sourceRef}
            />

            <input
              type="hidden"
              name="helper"
              value={selectedHelperUid}
              ref={helperRef}
            />

            <input
              type="hidden"
              name="teamMember"
              value={selectedTeamMemberUid}
              ref={teamMemberRef}
            />

            <input
              type="hidden"
              name="contactLocation"
              value={selectedContactLocationUid}
              ref={contactLocationRef}
            />

            {Object.keys(additionalData).map(key => (
              <input
                type="hidden"
                name={key}
                key={`add_data_${key}`}
                value={additionalData[key]}
              />
            ))}

            <p className="hidden">
              <label htmlFor="bot-field">
                Don’t fill this out if you're human:
                <input id="bot-field" name="bot-field" />
              </label>
            </p>

            <Grid container spacing={dense ? 2 : 0}>
              <Grid item xs={dense ? 3 : 12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  id="firstNameInput"
                  label={i18n.translations.contactFirstName}
                  name="firstName"
                  inputRef={firstNameRef}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={dense ? 3 : 12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  id="lastNameInput"
                  label={i18n.translations.contactLastName}
                  name="lastName"
                  inputRef={lastNameRef}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={dense ? 3 : 12}>
                <CustomTextField
                  fullWidth
                  type="email"
                  id="emailInput"
                  label={i18n.translations.contactEmail}
                  name="email"
                  inputRef={emailRef}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={dense ? 3 : 12}>
                <CustomTextField
                  fullWidth
                  type="tel"
                  pattern="[0-9\/|+\-\s]+"
                  id="phoneInput"
                  label={i18n.translations.contactPhone}
                  name="phone"
                  inputRef={phoneRef}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  id="messageInput"
                  label={contactMessage}
                  multiline
                  name="message"
                  inputRef={messageRef}
                  rows={7}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={dense ? 2 : 0}>
                  {showContactLocationSelector && (
                    <Grid item xs={dense ? 6 : 12}>
                      <CustomSelect
                        fullWidth
                        id="contactLocationInput"
                        label={i18n.translations.contactLocation}
                        name="contactLocation"
                        inputRef={contactLocationRef}
                        disabled={loading}
                        options={contactLocationsOptions}
                        defaultValue=""
                        onChange={handleLocationChange}
                      />
                    </Grid>
                  )}

                  {showTeamMemberSelector && (
                    <Grid item xs={dense ? 6 : 12}>
                      <CustomSelect
                        fullWidth
                        id="teamMemberInput"
                        label={i18n.translations.contactTeamMember}
                        name="teamMember"
                        inputRef={teamMemberRef}
                        disabled={loading}
                        options={teamMemberOptions}
                        defaultValue=""
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>


              {/* 
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={newsletterRef}
                      icon={<CheckBoxOutlineBlankRoundedIcon style={{ fontSize: 15, color: "#fff" }} />}
                      checkedIcon={<CheckBoxRoundedIcon style={{ fontSize: 15, color: "#fff" }} />}
                    />
                  }
                  label={<Typography style={{ color: "#fff", fontSize: 12 }}>
                    Sunt de acord să primesc Newsletter din partea ARES
                  </Typography>}
                />
              </Grid>
              */}
            </Grid>

            <Typography
              sx={{
                color: theme => theme.palette.common.white,
                fontSize: 12,
                textAlign: "center",
                paddingTop: 1,
                paddingBottom: 1,
              }}
              dangerouslySetInnerHTML={{
                __html: disclaimerMessage,
              }}
            />

            <CustomButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: 1,
                boxShadow: "none",
                borderRadius: 0,
                textTransform: "initial",
                fontSize: 14,
              }}
              disabled={loading}
            >
              {i18n.translations.sendMessage}
            </CustomButton>
          </form>
        </>
      )}
    </Box>
  )
}

export default GeneralContact

GeneralContact.propTypes = {
  formName: PropTypes.string,
  location: PropTypes.object.isRequired,
  locale: PropTypes.string,
  selectedHelperUid: PropTypes.string,
  selectedTeamMemberUid: PropTypes.string,
  selectedContactLocationUid: PropTypes.string,
  spread: PropTypes.bool,
  contactMessage: PropTypes.bool,
  disclaimerMessage: PropTypes.string,
  redirectTo: PropTypes.string,
  sendToCRM: PropTypes.bool,
}
