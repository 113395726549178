/**
 * Facebook Conversions API client-side utility
 * This service handles sending events to our Netlify function which forwards them to Facebook Conversions API
 */

import { v4 as uuidv4 } from 'uuid';

/**
 * Hash a string using SHA-256
 * @param {string} str - String to hash
 * @returns {Promise<string>} - Hashed string
 */
async function hashData(str) {
  if (!str) return '';

  try {
    // Encode as UTF-8
    const msgBuffer = new TextEncoder().encode(str.trim().toLowerCase());

    // Hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // Convert hash to hex string
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  } catch (error) {
    console.error('Hashing error:', error);
    return '';
  }
}

/**
 * Prepare user data with proper hashing for privacy
 * @param {Object} userData - User data to prepare
 * @returns {Promise<Object>} - Prepared user data object
 */
/**
 * Get IP address using a public API
 * Note: This is not very reliable and only used as a fallback
 * @returns {Promise<string|null>} - IP address or null if not available
 */
async function getIPAddress() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
}

async function prepareUserData(userData = {}) {
  const { email, phone, firstName, lastName, city, country, zipCode, ...rest } = userData;

  // Always include these fields according to Facebook best practices
  const preparedData = {
    ...rest,
    client_user_agent: navigator.userAgent,
    client_ip_address: '', // This will be set by the server
  };

  // Try to get IP address client-side as a fallback
  // Facebook needs this for better matching
  try {
    const ipAddress = await getIPAddress();
    if (ipAddress) preparedData.client_ip_address = ipAddress;
  } catch (error) {
    console.log('Could not retrieve IP address client-side');
  }

  // Hash user PII data if available
  if (email) {
    preparedData.em = await hashData(email);
  }

  if (phone) preparedData.ph = await hashData(phone);
  if (firstName) preparedData.fn = await hashData(firstName);
  if (lastName) preparedData.ln = await hashData(lastName);
  if (city) preparedData.ct = await hashData(city);
  if (country) preparedData.country = await hashData(country);
  if (zipCode) preparedData.zp = await hashData(zipCode);

  // Generate a temporary external_id if we don't have a user ID
  // This helps maintain consistency across events
  if (userData.userId) {
    preparedData.external_id = await hashData(userData.userId);
  } else {
    // Try to use a consistent identifier across sessions
    let tempUserId = localStorage.getItem('fbcapi_temp_id');
    if (!tempUserId) {
      tempUserId = uuidv4();
      localStorage.setItem('fbcapi_temp_id', tempUserId);
    }
    preparedData.external_id = await hashData(tempUserId);
  }

  // Add fbp (Facebook Browser Pixel) if available
  const fbp = getFacebookBrowserPixelId();
  if (fbp) preparedData.fbp = fbp;

  // Add fbc (Facebook Click ID) if available
  const fbc = getFacebookClickId();
  if (fbc) preparedData.fbc = fbc;

  return preparedData;
}

/**
 * Get Facebook Browser Pixel ID from cookie if available
 * @returns {string|null} - The fbp value or null
 */
function getFacebookBrowserPixelId() {
  const cookies = document.cookie.split(';');
  const fbpCookie = cookies.find(cookie => cookie.trim().startsWith('_fbp='));
  return fbpCookie ? fbpCookie.split('=')[1] : null;
}

/**
 * Get Facebook Click ID from URL or cookies
 * @returns {string|null} - The fbc value or null
 */
function getFacebookClickId() {
  // First check URL for fbclid parameter
  const urlParams = new URLSearchParams(window.location.search);
  const fbclid = urlParams.get('fbclid');

  if (fbclid) {
    // Format: fb.1.{timestamp}.{fbclid}
    return `fb.1.${Date.now()}.${fbclid}`;
  }

  // If not in URL, check cookies
  const cookies = document.cookie.split(';');
  const fbcCookie = cookies.find(cookie => cookie.trim().startsWith('_fbc='));
  return fbcCookie ? fbcCookie.split('=')[1] : null;
}

/**
 * Check if we're in development mode
 * @returns {boolean}
 */
const isDevelopment = () => {
  return process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';
};

/**
 * Send event to our Netlify function
 * @param {string} eventName - Name of the event (e.g., 'Purchase', 'Lead')
 * @param {Object} customData - Additional event data
 * @param {Object} userData - User data for the event
 * @returns {Promise<Object>} - Response from the server
 */
export async function trackEvent(eventName, customData = {}, userData = {}) {
  if (typeof window === 'undefined') return null; // Skip during SSR

  try {
    // Generate a unique event ID
    const eventId = uuidv4();

    // Prepare user data with proper hashing
    const preparedUserData = await prepareUserData(userData);

    // Add event-specific required fields for Facebook
    // For certain events, Facebook requires specific field formats
    let enhancedCustomData = { ...customData };

    // Add recommended fields if not already present
    if (eventName === 'ViewContent' && !enhancedCustomData.currency) {
      enhancedCustomData.currency = 'RON';
      if (!enhancedCustomData.value && enhancedCustomData.value !== 0) {
        enhancedCustomData.value = 0;
      }
    }

    if (eventName === 'Lead' && !enhancedCustomData.currency) {
      enhancedCustomData.currency = 'RON';
      if (!enhancedCustomData.value && enhancedCustomData.value !== 0) {
        enhancedCustomData.value = 0;
      }
    }

    // Prepare event data
    const eventData = {
      name: eventName,
      id: eventId,
      sourceUrl: window.location.href,
      customData: enhancedCustomData,
    };

    // Log in development mode
    if (isDevelopment()) {
      console.group(`🔍 Facebook Conversions API: ${eventName}`);
      console.log('Event Data:', eventData);
      console.log('User Data:', preparedUserData);
      console.groupEnd();
    }

    // Send to our Netlify function
    const response = await fetch('/.netlify/functions/fb-conversions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventData,
        userData: preparedUserData,
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      console.error('Error sending event to Conversions API:', result);

      // Show more detailed error in development
      if (isDevelopment() && result.error) {
        console.error('Facebook CAPI Error Details:', result.error);
        console.info('💡 Tip: Make sure you have set up the FACEBOOK_PIXEL_ID and FACEBOOK_API_ACCESS_TOKEN environment variables in your Netlify dashboard');
      }

      return { success: false, error: result };
    }

    // Log success in development
    if (isDevelopment()) {
      console.log(`✅ Successfully sent ${eventName} event to Facebook CAPI`);
    }

    return { success: true, data: result };
  } catch (error) {
    console.error('Failed to track event:', error);
    return { success: false, error };
  }
}

/**
 * Convenience methods for common events
 */
export const fbEvents = {
  // Standard events
  viewContent: (contentData, userData = {}) =>
    trackEvent('ViewContent', contentData, userData),

  lead: (leadData, userData = {}) =>
    trackEvent('Lead', leadData, userData),

  contact: (contactData, userData = {}) =>
    trackEvent('Contact', contactData, userData),

  schedule: (scheduleData, userData = {}) =>
    trackEvent('Schedule', scheduleData, userData),

  completeRegistration: (registrationData, userData = {}) =>
    trackEvent('CompleteRegistration', registrationData, userData),

  search: (searchData, userData = {}) =>
    trackEvent('Search', searchData, userData),

  // Custom healthcare events
  viewDoctor: (doctorData, userData = {}) =>
    trackEvent('ViewDoctor', doctorData, userData),

  viewTreatment: (treatmentData, userData = {}) =>
    trackEvent('ViewTreatment', treatmentData, userData),

  readArticle: (articleData, userData = {}) =>
    trackEvent('ReadArticle', articleData, userData),

  shareContent: (shareData, userData = {}) =>
    trackEvent('ShareContent', shareData, userData),
};
