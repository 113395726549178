import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import React from "react"

const PREFIX = "CustomSelect"

const classes = {
  root: `${PREFIX}-root`,
  focused: `${PREFIX}-focused`,
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&.MuiTextField-root": {
    backgroundColor: "#fcfcfb",
    borderRadius: 0,
    fontSize: 16,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 0,
  },
  "& .MuiInputLabel-root": {
    fontSize: 16,
    backgroundColor: "#fff",
    padding: "0 10px",
    marginLeft: "-5px",
  },
  /*
  [`& .${classes.root}`]: {
    overflow: "hidden",
    fontSize: 16,
    borderRadius: 0,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `none`,
    },
  },

  [`& .${classes.focused}`]: {
    borderColor: "red",
  }
    */
}))

export default function CustomSelect({ options, ...props }) {
  return (
    <StyledTextField
      size="small"
      select
      InputLabelProps={{}}
      InputProps={{ classes }}
      {...props}
      variant="outlined"
      margin="dense"
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledTextField>
  )
}
