module.exports = {
  ro: {
    default: true,
    path: "ro",
    locale: "ro-ro",
    siteLanguage: "ro",
    ogLang: "ro_RO",
    languageName: "Romana",
    ogUrl: "",
    title: "",
    translations: {
      search: "Caută",
      searchConditions: "Caută Afecțiuni",
      infos: "Informații Utile",
      team: "Echipa Medicală",
      call: "Sună",
      askPrice: "Solicită Preț",
      message: "Mesaj",
      video: "Video",
      presentation: "Prezentare",
      download: "Descarcă",
      open: "Deschide",
      viewProcedure: "Vezi Procedura",
      viewGuide: "Vezi Ghidul",
      mostSearchedProcedures: "Cele mai căutate proceduri",
      entryLine:
        "Clinica de cardiologie intervențională Centrele Monza Ares, București",
      readMore: "Citește mai mult",
      findOutMore: "Află mai mult",
      ourProcedures: "Procedurile Noastre",
      ourDepartments: "Departamentele Noastre",
      contactUsProcedure: "Află mai multe detalii",
      latestNews: "Ultimele noutăți",
      contactFirstName: "Prenume",
      contactLastName: "Nume",
      contactEmail: "E-Mail",
      contactPhone: "Telefon",
      contactTeamMember: "Alege medic",
      contactLocation: "Alege locație",
      contactDate: "Alege data",
      contactCode: "Cod",
      contactMessage: "Cu ce informații te putem ajuta?",
      contactSend: "Trimite",
      contactClose: "Închide",
      contactCancel: "Anulează",
      askDoctor: "Întreabă Medicul",
      questionAnswer: "Sfatul medicului",
      patientGuide: "Ghiduri pacienți",
      questionIntro:
        "Folosiți formularul de mai jos pentru a adresa medicilor specialiști întrebările dumneavoastră, iar în cel mai scurt timp posibil unul dintre specialiștii noștri vă va răspunde.",
      questionReceivedConfirmation:
        "Multumim pentru mesaj! Iti vom raspunde in cel mai scurt timp.",
      messageReceivedConfirmation:
        "Mulțumim pentru mesaj! Îți vom răspunde în cel mai scurt timp.",
      shortPolicy:
        "Acest site a fost conceput cu scop informativ. El nu inlocuieste in niciun caz consultul medical. Orice decizie privind diagnosticul si tratamentul afectiunii dumneavoastra se va face numai dupa un consult medical de specialitate.",
      contactIntro: "Specialiștii noștri îți sunt la dispoziție, sună acum!",
      callCenter: "Call Center",
      close: "Închide",
      homeName: "Acasă",
      summary: "Cuprins",
      writtenBy: "Articol medical scris de către",
      authorAt: "la",
      sendMessage: "Trimite mesaj",
      contactDisclaimer: `Prin apăsarea butonului <b>Trimite mesaj</b>, sunt de acord cu
      prelucrarea datelor mele cu caracter personal (ce pot include și
      date cu caracter medical) în vederea furnizării serviciilor de
      către MONZA ARES. Pentru mai multe informații, accesați pagina notei de
      informare.`,
      contactDisclaimerMediscInfo: `Prin apăsarea butonului <b>Trimite mesaj</b>, sunt de 
      acord cu prelucrarea datelor mele personale și să primesc informări medicale și 
      comerciale din partea MONZA ARES.`,
      priceList: {
        seePrices: "Vezi prețuri",
        search: "Caută investigație/procedură...",
        titlePrices: "Descarcă prețurile în format PDF",
        document: "Prețuri CAS",
        procedure: "Investigații și proceduri",
      },
      riskCalculator: {
        title: "Calculator de risc cardiovascular",
        age: "Vârsta",
        gender: "Sex",
        smoking: "Fumător",
        diabetes: "Diabetic",
        totalCholesterol: "Total colesterol",
        hdlCholesterol: "Colesterol HDL",
        systolicBloodPressure: "Tensiunea arterială sistolică",
        ageAtDiabetesDiagnosis: "Vârsta la diagnosticul de diabet",
        hba1c: "HbA1c",
        creatinine: "Creatinina",
        calculate: "Calculează",
        yes: "Da",
        no: "Nu",
        male: "Masculin",
        female: "Feminin",
        ani: "ani",
        error: {
          age: "Vârsta trebuie să fie 40 de ani sau mai mare.",
        },
        placeholder: {
          totalCholesterol: {
            1: "150 - 200 mg/dL",
            2: "3.9 - 5.2 mmol/L",
          },
          hdlCholesterol: {
            1: "0 - 60 mg/dL",
            2: "0 - 1.55 mmol/L",
          },
          systolicBloodPressure: "100 - 200 mm Hg",
          hba1c: {
            4: "4.0 - 5.6 %",
            5: "20.2 - 37.7 mmol/mol",
          },
          creatinine: {
            1: "0.7 - 1.3 mg/dL",
            3: "62 - 115 µmol/L",
          },
        },

        results: {
          risk: "Risc",
          empty: {
            title: "Niciun risc",
            description:
              "Completează formularul pentru a calcula riscul tău de a dezvolta o afecțiune cardiovasculară în următorii 10 ani.",
          },
          low: {
            title: "SCĂZUT",
            description:
              "Felicitări! Conform datelor pe care le-ai furnizat, riscul tău de a dezvolta o afecțiune cardiovasculară în următorii 10 ani este scăzut. Continuă să ai grijă de tine! Rămâi activ, mănâncă sănătos și bucură-te de un stil de viață echilibrat. Fiecare pas pe care îl faci contează pentru o inimă sănătoasă!",
            cta: "Descoperă <b>echipa noastră de medici cardiologi</b> pentru <b>controalele cardiologice de rutină și prevenție!</b>",
            buttonText: "Descoperă echipa",
          },
          moderate: {
            title: "MODERAT",
            description:
              "Conform datelor pe care le-ai furnizat, riscul tău de a dezvolta o afecțiune cardiovasculară în următorii 10 ani este moderat.",
            cta: "<b>Programează-te la consultație.</b> Completează formularul de contact și <b>te sunăm noi!</b>",
            buttonText: "Cick aici",
          },
          high: {
            title: "CRESCUT",
            description:
              "Conform datelor pe care le-ai furnizat, riscul tău de a dezvolta o afecțiune cardiovasculară în următorii 10 ani este crescut. Este crucial să te consulți cu un medic cardiolog cât mai curând posibil pentru o evaluare detaliată și pentru a discuta despre ce poți face pentru a reduce acest risc.",
            cta: "<b>Programează-te la consultație.</b> Completează formularul de contact și <b>te sunăm noi!</b>",
            buttonText: "Cick aici",
          },
        },
      },
    },
  },
  "en-us": {
    path: "en",
    locale: "en-us",
    siteLanguage: "en",
    ogLang: "en_US",
    languageName: "English",
    ogUrl: "",
    title: "",
    translations: {
      search: "Search",
      searchConditions: "Search Conditions",
      infos: "Info",
      team: "Medical Team",
      call: "Call",
      askPrice: "Request Price",
      video: "Video",
      presentation: "Presentation",
      download: "Download",
      open: "Open",
      viewProcedure: "View Procedure",
      viewGuide: "View Guide",
      mostSearchedProcedures: "Most searched procedures",
      entryLine:
        "Clinica de cardiologie intervențională Centrele Ares, București",
      readMore: "Read More",
      findOutMore: "Find out more",
      ourProcedures: "Our Procedures",
      contactUsProcedure: "Get more info",
      latestNews: "Latest News",
      contactFirstName: "First Name",
      contactLastName: "Last Name",
      contactEmail: "E-Mail",
      contactPhone: "Phone",
      contactTeamMember: "Choose medic",
      contactDate: "Choose date",
      contactCode: "Code",
      contactMessage: "How can we help you?",
      contactSend: "Send",
      askDoctor: "Ask Doctor",
      questionAnswer: "Doctors advice",
      patientGuide: "Patient Guide",
      questionIntro:
        "Use the form bellow to address your questions to our medics. One of our team member will answer you shortly.",
      questionReceivedConfirmation:
        "Thank you for your message! We will answer you shortly.",
      messageReceivedConfirmation:
        "Thank you for your message! We will answer you shortly.",
      shortPolicy:
        "This website has been designed for informational purposes. It does not replace the medical consultation in any case. Any decision regarding the diagnosis and treatment of your condition will be made only after a specialized medical consultation.",
      contactIntro: "Our specialists are available to you, call now!",
      callCenter: "Call Center",
      homeName: "Home",
      summary: "Summary",
      writtenBy: "Medical article written by",
      authorAt: "at",
      sendMessage: "Send message",
      contactDisclaimer: `Prin apăsarea butonului <b>Trimite mesaj</b>, sunt de acord cu
      prelucrarea datelor mele cu caracter personal (ce pot include și
      date cu caracter medical) în vederea furnizării serviciilor de
      către MONZA ARES. Pentru mai multe informații, accesați pagina notei de
      informare.`,
      contactDisclaimerMediscInfo: `Prin apăsarea butonului <b>Trimite mesaj</b>, sunt de 
      acord cu prelucrarea datelor mele personale și să primesc informări medicale și 
      comerciale din partea MONZA ARES.`,
      priceList: {
        seePrices: "Vezi prețuri",
        search: "Caută investigație/procedură...",
        titlePrices: "Descarcă prețurile în format PDF",
        document: "Prețuri CAS",
        procedure: "Investigații și proceduri",
      },
    },
  },
}
